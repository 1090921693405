import { elb } from '@elbwalker/walker.js';
import { Anchor, Body, Headline } from '@sumup-oss/circuit-ui';
import type { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Fragment } from 'react';
import { useIntl } from 'react-intl';

import { CreateForm } from '../../components/CreatePage/CreateForm';
import Auth, { Box } from '../../layouts/Auth';
import { config } from '../../lib/config/client';
import { config as serverConfig } from '../../lib/config/server';
import { handleServerError } from '../../lib/errors';
import { appendSafeQueryString, optionalQueryParam } from '../../lib/query';
import {
  type CreationError,
  defaultSignupCountries,
  errMissingChallenge,
  getInitialCountry,
  mapAccountCreationError,
  parseCountries,
  parseCountry,
} from '../../services/CreatePageService';
import type { ServerError } from '../../services/auth';
import type { Country } from '../../services/i18n';
import { loadTranslations, safeLocale } from '../../services/i18n';
import { getAppProps } from '../../services/mobile-apps';
import type { AppProps } from '../../types/app-props';
import type { I18nPageProps } from '../../types/i18n';
import { isString, isUndefined } from '../../utils/guards';

import styles from './styles.module.css';

type CreatePageProps = {
  challenge: string;
  submitError?: CreationError | ServerError;
  initialCountry: Country;
  countries: Country[];
  forceCountry: boolean;
  forceLogin: boolean;
  signupFormURL: string;
  loginHint?: string;
  invite?: string;
  accountName?: string;
  invitedBy?: string;
} & I18nPageProps &
  AppProps;

const CreatePage: NextPage<CreatePageProps> = ({
  challenge,
  submitError,
  initialCountry,
  countries,
  loginHint,
  forceCountry,
  forceLogin,
  iframeUri,
  iframeTitle,
  renderAppUI,
  signupFormURL,
  invite,
  accountName,
  kioskMode = false,
}: CreatePageProps) => {
  const intl = useIntl();
  const router = useRouter();
  const loginHref = appendSafeQueryString('login', router.query);

  return (
    <Fragment>
      <Head>
        <title key="title">
          {intl.formatMessage({
            defaultMessage: 'Create your login',
            description: 'Sign-up page title.',
          })}{' '}
          &mdash; SumUp
        </title>
      </Head>
      <Auth
        kioskMode={kioskMode}
        renderAppUI={renderAppUI}
        iframeUri={iframeUri}
        iframeTitle={iframeTitle}
      >
        <Box as="main" className={styles.box}>
          <Headline size="one" as="h1" className={styles.headline}>
            {invite
              ? intl.formatMessage(
                  {
                    id: 'create.join.headline',
                    defaultMessage:
                      'Create your profile to join {accountName} in SumUp',
                    description: 'Sign-up via invitation link heading.',
                  },
                  { accountName },
                )
              : intl.formatMessage({
                  defaultMessage: 'Register',
                  description: 'Sign-up form heading.',
                })}
          </Headline>
          {!renderAppUI && !invite && (
            <Body className={styles.body}>
              {intl.formatMessage({
                defaultMessage: 'Already registered?',
                description: 'Text shown next to a link to the login screen',
              })}{' '}
              <Anchor
                href={loginHref}
                variant="highlight"
                onClick={() => {
                  elb('button clicked', {
                    business_flow: 'signup',
                    button_description: 'login',
                  });
                }}
              >
                {intl.formatMessage({
                  defaultMessage: 'Log in',
                  description:
                    'Text for a link leading the user to the login screen.',
                })}
              </Anchor>
            </Body>
          )}
          <CreateForm
            challenge={challenge}
            initialCountry={initialCountry}
            initialEmail={loginHint}
            submitError={submitError}
            submitURL={signupFormURL}
            countryIsLocked={forceCountry}
            loginIsLocked={forceLogin}
            countries={countries}
            loginHref={loginHref}
            invite={invite}
          />
        </Box>
      </Auth>
    </Fragment>
  );
};

type CreatePageQueryParams = {
  login_challenge: string;
  error?: string;
  error_description?: string;
  in_app?: string;
  country_hint?: string;
  iframe_uri?: string;
  iframe_title?: string;
};

const getServerSidePageProps: GetServerSideProps<
  CreatePageProps,
  CreatePageQueryParams
> = async ({ req, query, locale: nextLocale, defaultLocale }) => {
  const locale = safeLocale(nextLocale || defaultLocale);

  if (!isString(query.login_challenge) || !query.login_challenge) {
    if (req.headers.referer) {
      return {
        redirect: {
          destination: req.headers.referer,
          permanent: false,
        },
      };
    }

    throw errMissingChallenge;
  }

  const messages = await loadTranslations(locale);

  const signupFormURL = appendSafeQueryString(
    `${config.urls.apiBasePath}/create`,
    query,
  );

  const errorParam = optionalQueryParam(query.error);
  const submitError = isUndefined(errorParam)
    ? undefined
    : mapAccountCreationError(errorParam);

  const loginHint = optionalQueryParam(query.login_hint);
  const ipCountry = parseCountry(req.headers['cf-ipcountry']);
  const countryHint = parseCountry(query.country_hint);
  const countries = parseCountries(query.available_countries) || [
    ...new Set([
      ...defaultSignupCountries,
      ...serverConfig.i18n.extraCountries,
    ]),
  ];
  const forceCountry =
    typeof optionalQueryParam(query.force_country) !== 'undefined';
  const forceLogin =
    typeof optionalQueryParam(query.force_login) !== 'undefined';
  const invite = optionalQueryParam(query.invite);
  const accountName = optionalQueryParam(query.account_name);
  const invitedBy = optionalQueryParam(query.invited_by);

  const initialCountry = getInitialCountry({
    locale,
    ipCountry,
    countries,
    countryHint,
  });

  const appProps = getAppProps(query);

  const props: CreatePageProps = {
    ...appProps,
    messages,
    challenge: query.login_challenge,
    // android check is a temporary solution, see PR for details
    initialCountry,
    countries,
    signupFormURL,
    forceCountry,
    forceLogin,
  };

  if (!isUndefined(loginHint)) {
    props.loginHint = loginHint;
  }

  if (!isUndefined(submitError)) {
    props.submitError = submitError;
  }

  if (!isUndefined(invite)) {
    props.invite = invite;
  }

  if (!isUndefined(invitedBy)) {
    props.invitedBy = invitedBy;
  }

  if (!isUndefined(accountName)) {
    props.accountName = accountName;
  }

  return {
    props,
  };
};

export const getServerSideProps = handleServerError<
  CreatePageProps,
  CreatePageQueryParams
>(getServerSidePageProps);

export default CreatePage;
