import rawConfig from './config.json';
import type { Country } from './i18n';

export enum MarketingConsentKind {
  OptIn = 'opt_in',
  OptOut = 'opt_out',
  Implicit = 'implicit',
}

interface MarketingConfig {
  // the kind of the consent to display (or not display if implicit)
  consentKind: MarketingConsentKind;
}

interface CountryConfig {
  marketing: MarketingConfig;
}

interface Config {
  fallback: CountryConfig;
  countries: Partial<Record<Country, CountryConfig>>;
}

const config = rawConfig as Config;

export const getCountryConfig = (country: Country): CountryConfig =>
  config.countries[country] || config.fallback;
