import { elb } from '@elbwalker/walker.js';
import { Button, Input, NotificationInline } from '@sumup-oss/circuit-ui';
import router from 'next/router';
import {
  type BaseSyntheticEvent,
  type FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { defineMessage, useIntl } from 'react-intl';

import { useBrowserRendered } from '../../../hooks/useBrowserRendered';
import {
  type CreationError,
  CreationErrorCode,
  checkPwnedPassword,
} from '../../../services/CreatePageService';
import type { ServerError } from '../../../services/auth';
import { MarketingConsentKind } from '../../../services/country-config';
import { getErrorMessage, onHookFormSubmit } from '../../../services/forms';
import { type Country, safeLocale } from '../../../services/i18n';
import { CountrySelect } from '../../CountrySelect';
import {
  ERROR_MESSAGES as EMAIL_ERROR_MESSAGES,
  EMAIL_PATTERN,
  EmailInput,
} from '../../EmailInput';
import {
  ERROR_MESSAGES as PASSWORD_ERROR_MESSAGES,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MIN_STRENGTH,
  PasswordInput,
} from '../../PasswordInput';
import { StrengthMeter } from '../../PasswordInput/StrengthMeter';
import { MarketingConsentCheckbox } from '../MarketingConsentCheckbox';
import {
  ERROR_MESSAGES as TERMS_ERROR_MESSAGES,
  TermsCheckbox,
} from '../Terms';
import { PrivacyLink } from '../Terms/PrivacyLink';
import { TermsLink } from '../Terms/TermsLink';

import styles from './styles.module.css';

import { getStrengthMeter } from 'lib/passwords';
import type { Meter } from 'lib/passwords';

export interface CreateFormProps {
  initialCountry: Country;
  initialEmail?: string;
  challenge: string;
  submitURL: string;
  submitError?: CreationError | ServerError;
  countries?: Country[];
  countryIsLocked?: boolean;
  loginIsLocked?: boolean;
  loginHref?: string;
  invite?: string;
}

type CreateFormFields = {
  zoneinfo: string;
  invite: string;
  challenge: string;
  email: string;
  password: string;
  country: Country;
  privacy_policy_accepted: boolean;
  marketing_consent: boolean;
  marketing_consent_kind: MarketingConsentKind;
  nickname: string;
};

const NICKNAME_ERROR_MESSAGES = {
  required: {
    message: defineMessage({
      defaultMessage: 'Please enter a name.',
      description: 'validation hint for missing preferred name',
    }),
    type: 'error',
  },
  pattern: {
    message: defineMessage({
      defaultMessage:
        "Please enter a name that's min 3 and max 50 characters long.",
      description:
        'Error message for the preferred name input when the user uses invalid characters.',
    }),
    type: 'error',
  },
} as const;

/**
 * The form for profile creation.
 */
export const CreateForm: FunctionComponent<CreateFormProps> = ({
  initialCountry,
  initialEmail,
  submitError,
  challenge,
  countries,
  countryIsLocked = false,
  loginIsLocked = false,
  loginHref,
  submitURL,
  invite,
}) => {
  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
  const intl = useIntl();
  const isBrowser = useBrowserRendered();
  const [passwordMeter, setPasswordMeter] = useState<Meter | undefined>(
    () => undefined,
  );
  const {
    control,
    handleSubmit,
    register,
    watch,
    unregister,
    formState: {
      isDirty,
      isSubmitting,
      isSubmitSuccessful,
      errors: {
        nickname: nicknameError,
        email: emailError,
        password: passwordError,
        privacy_policy_accepted: privacyPolicyError,
      },
    },
    setValue,
    setError,
  } = useForm<CreateFormFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      zoneinfo: '',
      email: initialEmail || '',
      password: '',
      challenge,
      country: initialCountry,
      privacy_policy_accepted: false,
      marketing_consent: false,
      marketing_consent_kind: MarketingConsentKind.OptIn,
    },
    shouldUseNativeValidation: !isBrowser,
    delayError: 500,
  });

  const country = watch('country', initialCountry);
  const email = watch('email', '');
  const previousCountryRef = useRef(country);
  const zoneInfo = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const locale = safeLocale(intl.locale);

  useEffect(() => {
    void getStrengthMeter({ locale }).then((meter) => {
      setPasswordMeter(() => meter);
    });
  }, [locale]);

  useEffect(() => {
    if (country !== previousCountryRef.current) {
      unregister('privacy_policy_accepted', {
        keepValue: false,
        keepDefaultValue: false,
        keepDirty: false,
        keepTouched: false,
        keepError: false,
      });

      previousCountryRef.current = country;
    }
  }, [country, unregister]);

  const handleSubmitCallback = useCallback(
    async (
      formValues: Record<string, unknown>,
      e: BaseSyntheticEvent | undefined,
    ) => {
      const password = formValues.password as string;

      elb('button clicked', {
        business_flow: 'signup',
        button_description: 'next',
      });

      if (passwordMeter) {
        const { score: passwordStrength } = passwordMeter(password, [email]);

        if (passwordStrength < PASSWORD_MIN_STRENGTH) {
          setError('password', {
            type: 'minStrength',
          });
          return null;
        }
      }

      const pwned = await checkPwnedPassword(password);

      if (pwned) {
        setError('password', {
          type: 'pwned',
        });
        return null;
      }

      return onHookFormSubmit(formValues, e);
    },
    [setError, passwordMeter, email],
  );

  const handleEmailSuggestion = (suggestion: string) => {
    setValue('email', suggestion);
  };

  const validatePasswordStrength = useCallback(
    (password: string): boolean => {
      if (!passwordMeter) {
        return true;
      }

      const { score } = passwordMeter(password, [email]);
      // Adding 1 to the general password score so we can use the level 0 as a neutral
      // point for the password validation.
      setPasswordStrengthScore(score + 1);
      return true;
    },
    [passwordMeter, email],
  );

  const passwordErrorMessage = getErrorMessage(
    PASSWORD_ERROR_MESSAGES,
    passwordError?.type,
  );

  const emailErrorMessage = getErrorMessage(
    EMAIL_ERROR_MESSAGES,
    emailError?.type,
  );

  const nicknameErrorMessage = getErrorMessage(
    NICKNAME_ERROR_MESSAGES,
    nicknameError?.type,
  );

  const termsErrorMessage = getErrorMessage(
    TERMS_ERROR_MESSAGES,
    privacyPolicyError?.type,
  );

  return (
    <form
      autoComplete="on"
      action={submitURL}
      method="POST"
      noValidate={isBrowser}
      onSubmit={handleSubmit(handleSubmitCallback)}
      className={styles.formContainer}
    >
      {submitError && !isDirty && (
        <NotificationInline
          headline={
            submitError.headline && {
              as: 'h6',
              label: intl.formatMessage(submitError.headline),
            }
          }
          body={
            submitError.error === CreationErrorCode.LeakedPassword
              ? intl.formatMessage(submitError.message, {
                  a: (str: React.ReactNode[]) =>
                    (
                      <a
                        href={`https://help.sumup.com/${locale}/articles/21nZk0DHwTr2rKBP3dVA2j`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {str}
                      </a>
                    ) as unknown as string,
                })
              : intl.formatMessage(submitError.message)
          }
          action={
            submitError.action && loginHref
              ? {
                  children: intl.formatMessage(submitError.action),
                  onClick: () => {
                    router.push(loginHref).catch(() => {});
                  },
                }
              : undefined
          }
          variant={submitError.variant ? submitError.variant : 'warning'}
          className={styles.notification}
        />
      )}
      <input type="hidden" {...register('challenge')} value={challenge} />
      <input type="hidden" {...register('zoneinfo')} value={zoneInfo} />
      {invite && <input type="hidden" {...register('invite')} value={invite} />}
      {invite && (
        <Input
          className={styles.bottomSpacingInput}
          label={intl.formatMessage({
            defaultMessage: 'Name',
            description: 'preferred name input label for invite flow',
          })}
          invalid={!!nicknameError}
          validationHint={
            nicknameErrorMessage && intl.formatMessage(nicknameErrorMessage)
          }
          {...register('nickname', {
            required: true,
            pattern: /^.{3,50}$/,
          })}
        />
      )}
      <EmailInput
        country={country}
        className={styles.bottomSpacingInput}
        useEmailSuggestion
        onEmailSuggestionClick={handleEmailSuggestion}
        invalid={!!emailError}
        readOnly={loginIsLocked}
        validationHint={
          emailErrorMessage && intl.formatMessage(emailErrorMessage)
        }
        {...register('email', {
          required: true,
          pattern: EMAIL_PATTERN,
        })}
      />
      <PasswordInput
        data-testid="password-input"
        className={styles.defaultInput}
        autoComplete="new-password"
        invalid={!!passwordError}
        validationHint={
          passwordErrorMessage &&
          intl.formatMessage(passwordErrorMessage, {
            a: (str: React.ReactNode[]) =>
              (
                <a
                  href={`https://help.sumup.com/${locale}/articles/21nZk0DHwTr2rKBP3dVA2j`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {str}
                </a>
              ) as unknown as string,
          })
        }
        passwordrules="minlength: 8"
        {...register('password', {
          required: true,
          minLength: PASSWORD_MIN_LENGTH,
          validate: (value) => validatePasswordStrength(value),
        })}
        aria-describedby="password-input-description"
      />
      <StrengthMeter
        score={passwordError ? 0 : passwordStrengthScore}
        passwordError={passwordError?.type}
      />
      {!invite && (
        <Controller
          control={control}
          name={'country'}
          rules={{ required: true }}
          render={({ field }) => (
            <CountrySelect
              {...field}
              disabled={countryIsLocked}
              className={styles.defaultInput}
              label={intl.formatMessage({
                defaultMessage: 'Country of your business',
                description:
                  'label for a select element where users pick the country their business operates in',
              })}
              countries={countries}
            />
          )}
        />
      )}
      {/* When the country is locked, we show a disabled input. Disabled inputs
       * are not treated as part of a form and are not submitted to the backend.
       * We render the input to still submit the value. */}
      {countryIsLocked && (
        <input type="hidden" {...register('country')} value={initialCountry} />
      )}
      {invite && (
        <div className={styles.inviteContainer}>
          <TermsLink country={country} />
          <div className={styles.inviteLink}>&#8226;</div>
          <PrivacyLink country={country} />
        </div>
      )}
      {!invite && (
        <TermsCheckbox
          className={styles.termsCheckbox}
          invalid={!!privacyPolicyError}
          validationHint={
            termsErrorMessage && intl.formatMessage(termsErrorMessage)
          }
          country={country}
          previousCountry={previousCountryRef.current}
          {...register('privacy_policy_accepted', {
            required: true,
          })}
        />
      )}
      {!invite && (
        <MarketingConsentCheckbox
          className={styles.bottomSpacingInput}
          country={country}
          registerConsent={() => register('marketing_consent')}
          registerConsentKind={() => register('marketing_consent_kind')}
        />
      )}
      <div className={styles.CTAContainer}>
        <Button
          variant="primary"
          className={styles.submitCTA}
          type="submit"
          isLoading={isSubmitting || isSubmitSuccessful}
          loadingLabel={intl.formatMessage({
            defaultMessage: 'Signing up',
            description:
              'Label for the spinner that is displayed when the signup form is being submitted.',
          })}
        >
          {intl.formatMessage({
            defaultMessage: 'Next',
            description: 'create account form submit button text',
          })}
        </Button>
      </div>
    </form>
  );
};
