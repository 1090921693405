import { defineMessage } from 'react-intl';

import { ERROR_MESSAGES as PASSWORD_ERROR_MESSAGES } from '../../components/PasswordInput';
import { config } from '../../lib/config/client';
import { AppError, HttpStatusCode } from '../../lib/errors';
import { isString, isUndefined } from '../../utils/guards';
import { type BackendError, type ServerError, mapServerError } from '../auth';
import { getLocaleCountry, isCountry } from '../i18n';
import type { Country, Locale } from '../i18n';

// Minimum number of times the password must have been leaked to trigger the "leaked" threshold.
const PWNED_THRESHOLD = 0;

export const defaultSignupCountries: Country[] = [
  'AT',
  'AU',
  'BE',
  'BG',
  'BR',
  'CH',
  'CL',
  'CO',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'NO',
  'PE',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
  'US',
];

export enum CreationErrorCode {
  Conflict = 'CONFLICT',
  InvalidEmail = 'INVALID_EMAIL',
  InviteExpired = 'INVITE_EXPIRED',
  LeakedPassword = 'LEAKED_PASSWORD',
}

export type CreationError = BackendError<CreationErrorCode>;

const accountCreationErrors = new Map<string, CreationError>()
  .set('invite_expired', {
    error: CreationErrorCode.InviteExpired,
    message: defineMessage({
      id: 'create.errors.invite_expired',
      defaultMessage:
        'This invitation link expired or was already used. Please ask the account administrator to invite you again.',
      description:
        'Error message shown to the user when they try to use expired invitation link.',
    }),
    variant: 'danger',
  })
  .set('creation_conflict', {
    error: CreationErrorCode.Conflict,
    headline: defineMessage({
      defaultMessage: 'There’s already a profile with this email address',
      description:
        'Error headline shown to the user when they enter an email address on the create profile page which is already registered with SumUp',
    }),
    message: defineMessage({
      defaultMessage:
        'Log in to the profile, or create a new profile with a different email address.',
      description:
        'Error message shown to the user when they enter an email address on the create profile page which is already registered with SumUp',
    }),
    action: defineMessage({
      defaultMessage: 'Log in with this email',
      description: 'Text for link that redirects to log-in page',
    }),
    variant: 'warning',
  })
  .set('invalid_email', {
    error: CreationErrorCode.InvalidEmail,
    message: defineMessage({
      defaultMessage:
        'This email address is invalid, please try with another one.',
      description:
        'Error message shown to the user when they enter an email address that contains invalid characters or is too long',
    }),
    variant: 'danger',
  })
  .set('leaked_password', {
    error: CreationErrorCode.LeakedPassword,
    message: PASSWORD_ERROR_MESSAGES.pwned.message,
    variant: 'danger',
  });

export const mapAccountCreationError = (
  errorCode: string,
): CreationError | ServerError =>
  mapServerError<CreationErrorCode>(accountCreationErrors, errorCode);

export const errMissingChallenge = new AppError({
  message: 'CHALLENGE_MISSING',
  userTitle: defineMessage({
    defaultMessage: 'We cannot create a profile',
    description:
      'Heading shown to users who visit the profile creation page without coming through an app like the Dashboard.',
  }),
  userMessage: defineMessage({
    defaultMessage:
      "You seem to have navigated to the profile creation page yourself. Please visit one of SumUp's applications to create a profile.",
    description:
      'Error message shown to users who visit the account creation page themselves instead of getting there from a an application like the Dashboard.',
  }),
  statusCode: HttpStatusCode.BadRequest,
  route: '/create',
  report: false,
});

export type GetInitialCountryParams = {
  locale: Locale;
  countryHint?: Country;
  ipCountry?: Country;
  countries?: Country[];
};

export function getInitialCountry({
  locale,
  countryHint,
  ipCountry,
  countries,
}: GetInitialCountryParams): Country {
  const country =
    parseCountry(countryHint) ||
    parseCountry(ipCountry) ||
    getLocaleCountry(locale);

  if (isUndefined(countries)) {
    return country;
  }

  if (countries.includes(country)) {
    return country;
  }

  return countries[0];
}

export function parseCountry(
  ipCountry: string | string[] | undefined,
): Country | undefined {
  // Most common case.
  if (isUndefined(ipCountry)) {
    return undefined;
  }

  let country = '';

  if (isString(ipCountry)) {
    country = ipCountry.toUpperCase();
  } else if (Array.isArray(ipCountry) && isString(ipCountry[0])) {
    country = ipCountry[0].toUpperCase();
  }

  if (isCountry(country)) {
    return country;
  }

  // Can't do anything.
  return undefined;
}

export function parseCountries(
  countriesParam: string | string[] | undefined,
): Country[] | undefined {
  if (!isString(countriesParam)) {
    return undefined;
  }

  const availableCountries = countriesParam
    .split(',')
    .reduce<Country[]>((countries, c) => {
      const upper = c.toUpperCase();

      if (isCountry(upper)) {
        return [...countries, upper];
      }

      return countries;
    }, []);

  if (availableCountries.length > 0) {
    return availableCountries;
  }

  return undefined;
}

interface PwnedPasswordResponse {
  pwned: number;
  ok: boolean;
  score: number;
}

export const checkPwnedPassword = async (
  password: string,
): Promise<boolean> => {
  try {
    const response = await fetch(config.urls.pwcheckURL, {
      method: 'POST',
      body: JSON.stringify({
        password,
      }),
    });
    const { pwned } = (await response.json()) as PwnedPasswordResponse;

    return pwned > PWNED_THRESHOLD;
  } catch {
    // Fail gracefully
    return false;
  }
};
