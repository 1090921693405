import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import type { OptionsType, ZxcvbnResult } from '@zxcvbn-ts/core';

import type { Locale } from 'services/i18n';

const defaultOptions: OptionsType = {
  useLevenshteinDistance: true,
};

export type Meter = (password: string, userInputs?: string[]) => ZxcvbnResult;

const getStrengthMeter = async ({
  locale,
}: {
  locale: Locale;
}): Promise<Meter> => {
  let options = defaultOptions;

  if (
    !('connection' in navigator) ||
    (navigator.connection as any)?.effectiveType === '4g'
  ) {
    const { dictionary, adjacencyGraphs } = await import(
      '@zxcvbn-ts/language-common'
    );
    options = {
      ...options,
      dictionary: {
        ...options.dictionary,
        ...dictionary,
      },
      graphs: adjacencyGraphs,
    };
  }

  switch (locale) {
    case 'en-AU':
    case 'en-GB':
    case 'en-IE':
    case 'en-MT':
    case 'en-US': {
      const { dictionary } = await import('@zxcvbn-ts/language-en');
      options = {
        ...options,
        dictionary: {
          ...options.dictionary,
          ...dictionary,
        },
      };
      break;
    }
    case 'de-AT':
    case 'de-CH':
    case 'de-DE':
    case 'de-LU': {
      const { dictionary } = await import('@zxcvbn-ts/language-de');
      options = {
        ...options,
        dictionary: {
          ...options.dictionary,
          ...dictionary,
        },
      };
      break;
    }
    case 'pt-BR': {
      const { dictionary } = await import('@zxcvbn-ts/language-pt-br');
      options = {
        ...options,
        dictionary: {
          ...options.dictionary,
          ...dictionary,
        },
      };
      break;
    }
    case 'es-ES': {
      const { dictionary } = await import('@zxcvbn-ts/language-es-es');
      options = {
        ...options,
        dictionary: {
          ...options.dictionary,
          ...dictionary,
        },
      };
      break;
    }
    default:
  }

  zxcvbnOptions.setOptions(options);

  return zxcvbn;
};

export { getStrengthMeter };
