import { type MessageDescriptor, defineMessage } from 'react-intl';

export type BackendError<T = string> = {
  error: T;
  message: MessageDescriptor;
  headline?: MessageDescriptor;
  action?: MessageDescriptor;
  variant?: 'danger' | 'warning' | 'success';
};

export enum ServerErrorCode {
  Unknown = 'UNKNOWN',
  ChallengeRetryAfter = 'CHALLENGE_RETRY_AFTER',
  ChallengeResendRetryAttempt = 'CHALLENGE_RESEND_RETRY_ATTEMPT',
  EmailVerificationResendRetryAfter = 'EMAIL_VERIFICATION_RESEND_RETRY_AFTER',
  EmailVerificationWrongSolution = 'EMAIL_VERIFICATION_WRONG_SOLUTION',
}

export type ServerError = BackendError<ServerErrorCode>;

const defaultError: BackendError<ServerErrorCode> = {
  error: ServerErrorCode.Unknown,
  message: defineMessage({
    defaultMessage: 'Something went wrong. Please try again in a few minutes.',
    description:
      'Default error message shown to the user when a login attempt fails for possibly unknown reasons or we do not want to be specific.',
  }),
  variant: 'danger',
};

export const challengeRetryAfterError: BackendError<ServerErrorCode> = {
  error: ServerErrorCode.ChallengeRetryAfter,
  message: defineMessage({
    defaultMessage: 'Too many attempts. Try again after {retryAfter}s.',
    description:
      'Error message shown to the user when they attempt too many solutions too fast.',
  }),
  variant: 'warning',
};

export const challengeResendRetryAttemptError: BackendError<ServerErrorCode> = {
  error: ServerErrorCode.ChallengeResendRetryAttempt,
  message: defineMessage({
    defaultMessage: 'Please wait {retryAfter}s before requesting a new code.',
    description:
      'Error message shown to the user when they attempt too resend the confirmation code via SMS too fast.',
  }),
  variant: 'warning',
};

export function mapServerError<T>(
  errorMap: Map<string, BackendError<T>>,
  errorCode?: string,
): BackendError<T> | BackendError<ServerErrorCode> {
  if (!errorCode) {
    return defaultError;
  }

  return errorMap.get(errorCode) || defaultError;
}
