import { Body, Checkbox, type CheckboxProps } from '@sumup-oss/circuit-ui';
import { Fragment, type FunctionComponent } from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  MarketingConsentKind,
  getCountryConfig,
} from '../../../services/country-config';
import type { Country } from '../../../services/i18n';

import styles from './styles.module.css';

export type MarketingConsentFormFields = {
  marketing_consent: boolean;
  marketing_consent_kind: MarketingConsentKind;
};

export type MarketingConsentCheckboxProps = {
  country: Country;
  registerConsent: () => UseFormRegisterReturn;
  registerConsentKind: () => UseFormRegisterReturn;
} & Partial<CheckboxProps>;

export const MarketingConsentCheckbox: FunctionComponent<
  MarketingConsentCheckboxProps
> = ({ country, registerConsent, registerConsentKind }) => {
  const intl = useIntl();
  const {
    marketing: { consentKind },
  } = getCountryConfig(country);

  return (
    <Fragment>
      <input type="hidden" value={consentKind} {...registerConsentKind()} />
      {consentKind === MarketingConsentKind.OptIn && (
        <Checkbox
          {...registerConsent()}
          value="true"
          label={
            intl.formatMessage(
              {
                defaultMessage:
                  'I want to stay up to date with SumUp’s latest news and offers and agree to receive any related communication. {optional}',
                description:
                  'user want to subscribe to latest news and offer newsletter',
              },
              {
                optional: (
                  <Body as="span" className={styles.checkboxBody}>
                    (
                    {intl.formatMessage({
                      defaultMessage: 'Optional',
                      description: 'this input is optional',
                    })}
                    )
                  </Body>
                ),
              },
            ) as unknown as string
          }
        />
      )}
      {consentKind === MarketingConsentKind.OptOut && (
        <Checkbox
          {...registerConsent()}
          value="true"
          label={
            intl.formatMessage(
              {
                defaultMessage:
                  '{optional} We will occasionally contact you with our latest news and offers. You can unsubscribe at any time. By ticking this box you indicate that you do not want to be contacted. ',
                description:
                  "user doesn't want to subscribe to latest news and offer newsletter",
              },
              {
                optional: (
                  <Body as="span" className={styles.checkboxBody}>
                    (
                    {intl.formatMessage({
                      defaultMessage: 'Optional',
                      description: 'this input is optional',
                    })}
                    )
                  </Body>
                ),
              },
            ) as unknown as string
          }
        />
      )}
      {consentKind === MarketingConsentKind.Implicit && (
        <input type="hidden" value="true" {...registerConsent()} />
      )}
    </Fragment>
  );
};
